import React from "react"

import { withHero } from "./withHero"
import { HeroCard } from "./Card/HeroCard"

export const Hero = withHero(
  ({ card, ratio, handlePromoClick }): JSX.Element => (
    <HeroCard card={card} ratio={ratio} handlePromoClick={handlePromoClick} />
  ),
)

export default Hero
